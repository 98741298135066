import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, {StylesConfig} from "react-select";
import './Strain.css';
import './UpdateStrain.css';
import Button from '../Button';
import CancelButton from '../CancelButton';
import ErrorText from '../ErrorText';
import MainContentView from '../MainContentView';
import TitleEditor from '../TitleEditor';
import { useRootStore } from "../StoreProvider";
import { observer } from 'mobx-react';
import { Dry } from "../stores/DryStore";
import { Strain } from '../stores/StrainStore';
import { validateStrainForm, StrainFormErrors } from '../FormValidators';
import DryMoistureChart from '../dries/DryMoistureChart';
import QuestionMarkPopup from '../QuestionMarkPopup';
import moment from 'moment';

interface UpdateStrainProps {
  strain: Strain;
  candidate_dries: Array<Dry>;
}

interface OptionType {
    label: string;
    value: string | null;
    name: string | null;
    data: any;
    startTime: string | undefined;
  }

  const nullOption: OptionType = {
    label: "No Ideal Moisture Curve",
    name: null,
    value: null,
    data: null,
    startTime: undefined,
  };
  

const UpdateStrain = observer(function UpdateStrain(props: UpdateStrainProps) {
  const { strain, candidate_dries } = props;
  const { dryStore } = useRootStore();
  const navigate = useNavigate();
  const [saveClicked, setSaveClicked] = useState(false);
  const initialErrors: StrainFormErrors = {};
  
  // Options for the select box. Include a null option.
  const idealDryOpts: OptionType[] = [
    nullOption,
    ...candidate_dries.map((d) => ({
      label: `${d.name} - ${moment(d.starttime).format("M/D/YY h A").valueOf()}`,
      value: d.id,
      name: d.name,
      data: d.timeseriesData,
      startTime: d.starttime
    }))
  ];

  const [formErrors, setFormErrors] = useState(initialErrors);
  const [moistureTarget, setMoistureTarget] = useState(strain.moisture_target || '');
  const [moistureSpatialVariability, setMoistureSpatialVariability] = useState(strain.moisture_spatial_variability || '');


  const idealDryDefault = strain.ideal_dry_id
    ? idealDryOpts.find((o) => o.value === strain.ideal_dry_id)
    : nullOption;

  const [selectedDryData, setSelectedDryData] = useState<any>(idealDryDefault?.data);
  const [selectedDryLabel, setSelectedDryLabel] = useState<any>(idealDryDefault?.label);
  const [selectedDryName, setSelectedDryName] = useState<any>(idealDryDefault?.name);
  const [selectedDryStartTime, setSelectedDryStartTime] = useState<any>(idealDryDefault?.startTime);


  const handleDryNameClick = () => {
    if (strain.ideal_dry_id) {
      navigate(`/home/dries/${strain.ideal_dry_id}`);
    }
  };

  const customSelectStyles : StylesConfig<OptionType, false> = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    })
  };

  useEffect(() => {
    return function cleanup() {
      strain.discardChanges();
    };
  }, [strain]);

  const changeIdealDry = (idealDryId: string | null) => {
    if (idealDryId === strain.ideal_dry_id) {
      return;
    }
    strain.setIdealDryId(idealDryId);
    const selectedDry = idealDryOpts.find((o) => o.value === idealDryId);
    setSelectedDryData(selectedDry?.data || null);
    setSelectedDryLabel(selectedDry?.label || null);
    setSelectedDryName(selectedDry?.name || null);
    setSelectedDryStartTime(selectedDry?.startTime || null);
  };
  const onSave = () => {
    const errors = validateStrainForm(strain);
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      setSaveClicked(true);
      strain.save().then(resp => {
        if (resp.status === 200 || resp.status === 201) {
          navigate(`/settings/strains/${resp.data.id}`);
        }
        setSaveClicked(false);
      }).catch(err => setSaveClicked(false));
    }
  }

  return (
    <MainContentView>
      <div className="Strain-header">
        <TitleEditor
          title={strain.name}
          onChange={(evt) => {
            strain.setName(evt.target.value);
          }}
          setIsEditingFromOutside={() => {}}
          value={strain.name}
        />
    
      </div>
      {formErrors.name &&
          <ErrorText>{formErrors.name}</ErrorText>
        }
      <div className="my-12">
        <div className="Strain-info-row">
          <div className="Strain-info-key">Target Moisture Content:</div>
          <div>
            <input
              className="Strain-moisture-input"
              type="number"
              value={moistureTarget}
              onChange={(evt) => {
                const val = Number.parseFloat(Number.parseFloat(evt.target.value).toFixed(1)) || '';
                const spaceVal = val || 0;
                setMoistureTarget(val);
                strain.setMoistureTarget(spaceVal);
              }}
            />%
          </div>
          {formErrors.moisture_target &&
            <ErrorText>{formErrors.moisture_target}</ErrorText>
          }
        </div>
        <div className="Strain-info-row">
            <div className="flex items-center space-x-2">
                <h2>Ideal Moisture Curve </h2>
                <QuestionMarkPopup text="The ideal moisture curve can be used as a guide when you are drying this strain. \n To use: \n1. Select an ideal dry from the drop down menu. \n2. When you create a new dry with this strain, the ideal moisture curve will appear as a guide. \nClick for more information." url="https://support.growvera.com/zone/ideal-moisture-curves"/>
            </div>
        </div>
        <div className="Strain-info-row">
          <div className="Strain-info-key">Select Ideal Dry:</div>
            <Select
              defaultValue={idealDryDefault}
              onChange={(dry) => {
                if (dry) {
                  changeIdealDry(dry.value);
                }
              }}
              options={idealDryOpts}
              className="UpdateStrain-dropdown"
              placeholder="Select Ideal Dry Here"
              styles={customSelectStyles}
              menuPortalTarget={document.body}
            />
          </div>
        <div>
        {selectedDryData && (
            <>
            <div
            style={{
            display: "flex",
            alignItems: "center",
            margin: "0.5em",
            }}>
            <h2 style={{ margin: 0 }}>
                        Moisture Data From Dry:{" "}
                        <span
                        className="UpdateStrain-ideal-dry-link"
                        onClick={handleDryNameClick}
                        >
                        {selectedDryName}
                        </span>
                    </h2>
        </div>
          <div>
            <div key={"timeseries" + (selectedDryLabel || 'default') + moistureTarget} className="DryMoistureChart">
              <DryMoistureChart data={selectedDryData} dryName={(selectedDryLabel || 'default') } dryStartTime={selectedDryStartTime} moistureTarget={moistureTarget}/>
            </div>
        </div>
        </>
        )}
        </div>
      </div>
        {/* <div className="Strain-info-row">
          <div className="Strain-info-key">Moisture Variability:</div>
          <div>
            <input
              className="Strain-moisture-input"
              type="number"
              value={moistureSpatialVariability}
              onChange={(evt) => {
                const val = Number.parseInt(evt.target.value) || '';
                const spaceVal = val || 0;
                setMoistureSpatialVariability(val);
                strain.setMoistureSpatialVariability(spaceVal);
              }}
            />%
          </div>
          {formErrors.moisture_spatial_variability &&
            <ErrorText>{formErrors.moisture_spatial_variability}</ErrorText>
          }
        </div> */}
      <div className="flex flex-col md:flex-row items-center">
        <Button className='mx-4' onPress={onSave} disabled={saveClicked}>
          {saveClicked ?
            'SAVING...'
            : strain.id ? 'UPDATE STRAIN' : 'CREATE NEW STRAIN'
          }
        </Button>
        <br/>
        {!saveClicked &&
          <CancelButton onCancel={() => {
            strain.discardChanges();
            navigate(-1);
          }} />
        }
      </div>
      {Object.keys(formErrors).length > 0 &&
        <div>
          <ErrorText>Check for errors in the fields above</ErrorText>
        </div>
      }
    </MainContentView>
  );
});

export default UpdateStrain;
