import React, { useState } from "react";
import "./Strain.css";
import { observer } from "mobx-react";
import { useParams, useNavigate } from "react-router-dom";
import { useRootStore } from "../StoreProvider";
import BackButton from "../BackButton";
import DeleteWithConfirmation from "../DeleteWithConfirmation";
import Edit from "../Edit";
import MainContentView from "../MainContentView";
import NotFound from "../NotFound";
import StatBox from "../StatBox";
import IdealDryChart from "./IdealDryChart";
import QuestionMarkPopup from "../QuestionMarkPopup";

const Strain = observer(function Strain() {
  const { strainStore, dryStore } = useRootStore();
  const { id } = useParams();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const navigate = useNavigate();

  if (!strainStore.loaded || id === undefined) {
    return <NotFound />;
  }

  const strain = strainStore.strains.get(id);
  if (!strain) {
    return <NotFound />;
  }

  let ideal_dry_name: String | null = null;
  if (strain.ideal_dry_id !== undefined && strain.ideal_dry_id !== null) {
    ideal_dry_name = dryStore.userDries.get(strain.ideal_dry_id)?.name || null;
  }

  const handleDryNameClick = () => {
    if (strain.ideal_dry_id) {
      navigate(`/home/dries/${strain.ideal_dry_id}`);
    }
  };

  const onDelete = () => {
    setDeleteInProgress(true);
    strain.remove().then(async (resp) => {
      if (resp.status === 204) {
        navigate("/settings");
      } else {
        setDeleteInProgress(false);
      }
    });
  };

  if (deleteInProgress) {
    return (
      <MainContentView>
        <h2>Deleting strain...</h2>
      </MainContentView>
    );
  }

  return (
    <MainContentView>
      <BackButton to="/settings">Back to Settings</BackButton>
      <div className="Strain-header">
        <h1 className="Strain-name">{strain.name}</h1>
        <Edit to="update" />
        <DeleteWithConfirmation
          key={strain.id}
          onDelete={onDelete}
          itemName={strain.name}
          itemType={"strain"}
        />
      </div>
      <div className="Strain-stat-boxes">
        <StatBox title="Target Moisture">{strain.moisture_target}%</StatBox>
        {/* <StatBox title="Moisture Variability">
          {strain.moisture_spatial_variability}%
        </StatBox> */}
      </div>
      <div>
        {strain.ideal_dry_data.time_elapsed_hrs.length > 0 ? (
            <>     
                <div className="flex items-center space-x-2">
                    <h2>
                        Ideal Moisture Curve Based On Dry:{" "}
                        <span
                        className="Strain-ideal-dry-link"
                        onClick={handleDryNameClick}
                        >
                        {ideal_dry_name}
                        </span>
                    </h2>
                    <QuestionMarkPopup text="This ideal moisture curve can be used as a guide when you are drying this strain. \nIt will appear on the 'Data History' plot once you start the dry. \nClick for more information." url="https://support.growvera.com/zone/ideal-moisture-curves"/>
                </div>
                <div key={"idealprofile" + strain.id} className="IdealDryChart-chart">
                <IdealDryChart data={strain.ideal_dry_data} dryName={strain.name} targetMoisture={strain.moisture_target} />
                </div>
            </>
          ) : (
            <div className="flex items-center space-x-2">
                <h2>No Ideal Moisture Curve: Edit the Strain to Select One </h2>
                <QuestionMarkPopup text="This ideal moisture curve can be used as a guide when you are drying this strain. \nIt will appear on the 'Data History' plot once you start the dry. \nClick for more information." url="https://support.growvera.com/zone/ideal-moisture-curves"/>
            </div>
          )}
      </div>
    </MainContentView>
  );
});

export default Strain;
